import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Briefcase } from 'lucide-react';

const workExperiences = [
  {
    company: "Eikon Technology",
    position: "Cloud Engineer",
    period: "Sep 2024 - Present",
    description: [
      "Learning About GCP (Google Cloud Platform)",
      "Worked on projects for clients."
    ]
  },
  {
    company: "PT Hero Intiputra",
    position: "IT Web Developer & Mobile Apps",
    period: "Nov 2013 - Sep 2024",
    description: [
      "Designed, developed, and maintained software applications, while managing business websites, and fixing bugs for both web and mobile applications.",
      "Created mobile applications to support business growth.",
      "Maintained domains and hosting to ensure operational continuity.",
      "Manage business websites, maintained domains and hosting",
      "Stay updated with emerging technologies and industry trends",
      "Integrated websites and mobile apps with APIs."
    ]
  },
  {
    company: "Altermyth",
    position: "Junior PHP Developer",
    period: "Jun 2011 - Mar 2013",
    description: [
      "Developed a download platform backend using PHP and MySQL.",
      "Tested websites, applications, and games for security and bugs.",
      "Created a project management website for effective monitoring."
    ]
  }
];

export function WorkHistory() {
  return (
    <section id="work-history" className="py-20 bg-gradient-to-b from-background to-muted">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold mb-12 text-center flex items-center justify-center gap-2"
        >
          <Briefcase className="text-primary" />
          Work Experience
        </motion.h2>
        <div className="relative">
          {/* Vertical line */}
          <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-primary/20"></div>
          
          {workExperiences.map((job, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="mb-12 ml-12 relative"
            >
              {/* Circle on the timeline */}
              <div className="absolute -left-[41px] top-0 w-6 h-6 bg-primary rounded-full border-4 border-background"></div>
              
              {/* Job details */}
              <div className="bg-card text-card-foreground rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow">
                <div className="flex items-center gap-2 text-sm text-muted-foreground mb-2">
                  <Calendar size={16} />
                  <span>{job.period}</span>
                </div>
                <h3 className="text-xl font-semibold mb-1">{job.position}</h3>
                <h4 className="text-lg text-primary mb-4">{job.company}</h4>
                <ul className="space-y-2">
                  {job.description.map((item, i) => (
                    <li key={i} className="text-muted-foreground flex items-start">
                      <span className="mr-2 mt-1.5 h-1.5 w-1.5 flex-shrink-0 rounded-full bg-primary"></span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}