import React from 'react';
import { motion } from 'framer-motion';
import { Code2, Cpu, Globe } from 'lucide-react';

export function About() {
  const skills = [
    { icon: Code2, title: 'Web Development', description: 'Expert in PHP, Laravel, Codeigniter, Node.js, React and modern web technologies' },
    { icon: Cpu, title: 'Mobile Development', description: 'Experienced in Flutter and mobile app deployment' },
    { icon: Globe, title: 'Cloud Engineering', description: 'Proficient in GCP and cloud infrastructure' },
  ];

  return (
    <section id="about" className="py-20 bg-muted">
      <div className="container mx-auto px-6">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col md:flex-row items-center gap-12 mb-16"
          >
            <div className="w-full md:w-1/3">
              <div className="relative w-64 h-64 mx-auto">
                <div className="absolute inset-0 rounded-full" style={{ padding: '3px' }}>
                  <div className="bg-background w-full h-full rounded-full overflow-hidden">
                    <img
                      src="/images/profile.jpeg?height=256&width=256"
                      alt="Aditia"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/3 text-center md:text-left">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary mb-4"
              >
                ADITIA
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="text-2xl md:text-3xl font-semibold text-primary mb-6"
              >
                IT Web & Mobile Apps Developer
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="text-lg leading-relaxed text-muted-foreground"
              >
                I'm a professional in web and mobile application development. Currently, I work at Eikon
                Technology as a Cloud Engineer, supporting the company's business operations and
                strategies. With my experience, I am committed to providing innovative and efficient
                solutions to improve business performance and growth.
              </motion.p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="grid md:grid-cols-3 gap-8"
          >
            {skills.map((skill, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
                className="bg-card p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="bg-primary/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <skill.icon className="text-primary" size={24} />
                </div>
                <h3 className="text-xl font-semibold mb-2">{skill.title}</h3>
                <p className="text-muted-foreground">{skill.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
}