import React from 'react';
import { Github, Linkedin, Mail, Twitter } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-background py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <p>&copy; 2024 Aditia. All rights reserved.</p>
          </div>
          <div className="flex space-x-4">
            <a href="#" className="text-foreground hover:text-primary transition-colors" aria-label="GitHub">
              <Github size={24} />
            </a>
            <a href="#" className="text-foreground hover:text-primary transition-colors" aria-label="LinkedIn">
              <Linkedin size={24} />
            </a>
            <a href="mailto:your.email@example.com" className="text-foreground hover:text-primary transition-colors" aria-label="Email">
              <Mail size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}