import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Code, Smartphone, Cloud } from 'lucide-react';

export function Hero() {
  return (
    <section className="min-h-screen flex items-center justify-center bg-gradient-to-br from-primary/10 via-background to-secondary/10">
      <div className="text-center px-4 max-w-4xl">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary"
        >
          Aditia
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-3xl md:text-4xl font-semibold mb-8 text-foreground"
        >
          IT Web & Mobile Apps Developer
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-xl md:text-2xl mb-12 text-muted-foreground"
        >
          Crafting innovative digital solutions for web and mobile platforms
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="flex flex-wrap justify-center gap-4 mb-12"
        >
          <div className="flex items-center bg-primary/10 text-primary px-4 py-2 rounded-full">
            <Code className="mr-2" size={20} />
            <span>Web Development</span>
          </div>
          <div className="flex items-center bg-secondary/10 text-secondary px-4 py-2 rounded-full">
            <Smartphone className="mr-2" size={20} />
            <span>Mobile Apps</span>
          </div>
          <div className="flex items-center bg-muted text-muted-foreground px-4 py-2 rounded-full">
            <Cloud className="mr-2" size={20} />
            <span>Cloud Engineering</span>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <a
            href="#projects"
            className="inline-flex items-center bg-primary text-primary-foreground px-6 py-3 rounded-full hover:bg-primary/90 transition-colors group"
          >
            View My Projects
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </a>
        </motion.div>
      </div>
    </section>
  );
}